body:has(.demo2){
	--color-text: #66665d;
	--color-bg: #0e0e0d;
	--color-link: #cc0000;
	--color-link-hover: #fff;
	--color-gallery-title: #d9d0be;
	--color-gallery-number: #181817;
	--color-gallery-link: #fff;
	--color-gallery-link-bg: #cc0000;
	--color-gallery-link-hover: #0e0e0d;
	--color-gallery-link-bg-hover: #d9d0be;
	--cursor-stroke: none;
	--cursor-fill: #cc0000;
	--cursor-stroke-width: 1px;
}

.demo2 .rotate {
	transform: rotate(-4deg);
}

.demo2 .gallery {
	display: flex;
	padding-right: 12vw;
	margin-left: 5vw;
}

.demo2 .gallery__item {
	margin: 0 3vw;
	display: grid;
	grid-template-areas: '... ...' 
						 '... gallery-image'
						 '... ...' ;
	grid-template-columns: 8rem 21vmax;
	grid-template-rows: 8rem 28vmax 3rem;
}

.demo2 .gallery__item-img {
	grid-area: gallery-image;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	will-change: transform;
}

.demo2 .gallery__item-imginner {
	background-size: cover;
    background-position: 50% 0;
	width: calc(100% + 10vw);
	margin-left: -5vw;
    height: 100%;
    will-change: transform;
}

.demo2 .gallery__item-caption {
	grid-area: 1 / 1 / 4 / 3;
	display: grid;
	grid-template-areas: 'gallery-number gallery-title' 
						 'gallery-link ...'
						 'gallery-link gallery-tags' ;
	grid-template-columns: 8rem auto;
	grid-template-rows: 8rem auto 3rem;

}

.demo2 .gallery__item-number {
	grid-area: gallery-number;
	font-size: 6rem;
	font-size: clamp(2.5rem,9vw,6.5rem);
	justify-self: end;
	padding-right: 2rem;
	color: var(--color-gallery-number);
}

.demo2 .gallery__item-title {
	grid-area: gallery-title;
	margin: 0;
	font-size: 4rem;
	font-size: clamp(2rem,5vw,4rem);
	color: var(--color-gallery-title);
}

.demo2 .gallery__item-number,
.demo2 .gallery__item-title,
.demo2 .gallery__text {
	font-family: moret, serif;
	font-weight: 800;
	font-style: italic;
	align-self: center;
}

.demo2 .gallery__item-link {
	background: var(--color-gallery-link-bg);
	color: var(--color-gallery-link);
}
.demo2 .gallery__item-link:hover {
	color: var(--color-gallery-link-hover);
}
.demo2 .gallery__item-link::after {
	background-color:  var(--color-gallery-link-bg-hover);
}

.demo2 .gallery__item-tags {
	grid-area: gallery-tags;
	justify-self: end;
	font-size: 1.5rem;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1rem;
	cursor: pointer;
}

.demo2 .gallery__text {
	font-size: 20vw;
	line-height: 0.8;
	margin: 0 12vw;
	text-transform: lowercase;
	color: transparent;
	-webkit-text-stroke: 1px #645c5b;
	text-stroke: 1px #645c5b;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
}

.demo2 .gallery__text-inner {
	display: block;
}

@media screen and (min-width: 53em) {
	.demo2 .frame {
		grid-template-areas: 'title demos demos links';
	}
	.demo2 .frame__info {
		justify-self: end;
	}
}
