
@import url("https://use.typekit.net/vxy2fer.css");
@import url("./base.css");
@import url("./demo1.css");
@import url("./demo2.css");
@import url("./demo3.css");
@import url("./demo4.css");
@import url("./demo5.css");
/* @import url("./test.css"); */

body{
    transition: 0.6s background ease-in-out;
}